@use 'src/styles/variables' as *


.form
  position: relative
  h1
    //margin-bottom: .9em
    text-transform: uppercase
    text-align: center

.block
  display: grid
  grid-template-columns: 1fr
  gap: 15px
  max-width: 600px
  margin: 50px auto 30px

.notificationWrap
  position: fixed
  left: 0
  right: 0
  top: 0
  bottom: 0
  backdrop-filter: blur(10px)
  z-index: 2

.notification
  position: absolute
  left: 50%
  top: 50%
  transform: translate(-50%, -50%)
  border: 2px solid $accent
  background: #fff
  padding: 30px 40px
  border-radius: 6px
  font: 500 18px/1.2 $mainFont
  text-align: center
  text-transform: uppercase

.empty
  padding: 30px 0
  a
    max-width: 300px

.cart
  max-width: 90%
  margin: 0 auto
.cartTitle
  text-align: center
  font-size: 2em
  margin-bottom: 0.6em

.table
  display: grid
  grid-template-columns: 1fr
  border: 1px solid #000
  border-radius: 3px
.cartItem, .header
  display: grid
  grid-template-columns: 1fr 4fr 1fr 1fr 1fr
  gap: 20px
  padding: 3px
  &:not(:last-child)
    border-bottom: 1px solid #000

.header
  font-size: 12px

.itemPrice, .sum
  display: flex
  justify-content: flex-start
  align-items: center
  font: 600 18px/1.2 $mainFont

.total
  text-align: right
  font: 500 20px/1.2 $mainFont
  padding-top: .5em
.img
  display: block

.itemName
  display: flex
  align-items: baseline
  gap: .5em
  font: 500 19px/1.2 $mainFont
  padding-bottom: 0.6em

.category
  font-size: 12.5px
  font-style: italic

.non
  font-size: 14px
  color: $red

.note
  padding-top: 0.6em
  font-size: 13px
  font-style: italic

.buttons
  display: flex
  align-items: center
  justify-content: flex-start
  gap: 10px
  span
    font: 400 16px/1.2 $mainFont
  button
    border-radius: 3px
    display: flex
    align-items: center
    justify-content: center
    font-size: 22px
    color: #fff
    width: 25px
    height: 25px
    line-height: 1
    &:disabled
      opacity: 0.6
    &[value="minus"]
      background-color: $red
    &[value="plus"]
      background-color: $green
.submit
  margin-top: 1em

.viewer
  width: 100%
  height: 1000px

.buttonRow
  display: flex
  align-items: center
  justify-content: center
  gap: 20px
  button
    width: 200px