@use 'src/styles/variables' as *

.loginForm
  margin-top: 64px


.block
  display: grid
  grid-template-columns: 1fr
  gap: 15px
  max-width: 600px
  margin: 50px auto 30px

.row
  position: relative
  width: 100%

.input
  background: transparent
  border-bottom: 2px solid #585E6C
  padding: 16px 0 10px
  font: 400 16px/20px $mainFont
  transition: all .2s ease
  max-width: 600px
  &.inputError
    border-color: $red
  &.focused, &:hover
    border-color: $accent
  &.focused
    & + .label
      top: 2px
      font: 400 12px/15px $mainFont
      color: #8D8D8D

.select
  max-width: 250px

.selectImg
  max-width: 600px

.selectImgOption
  display: grid
  grid-template-columns: 100px 1fr
  gap: 10px
  cursor: pointer
  img
    border-radius: 3px
    aspect-ratio: 16 / 9
  span
    display: flex
    align-items: center
    justify-content: flex-start
    font: 500 20px/1 $mainFont

.error
  position: absolute
  top: calc(100% + 3px)
  color: $red
  font: 400 14px/17px $mainFont
  white-space: nowrap

.label
  position: absolute
  transition: all .2s ease
  top: 12px
  left: 0

.selectLabel
  position: absolute
  transition: all .2s ease
  top: 50%
  transform: translateY(-50%)
  left: 5px
  font-weight: 500

.checkboxSelect
  position: relative
  input
    position: absolute
    z-index: -1
    opacity: 0
    width: 100%
    height: 100%
.checked
  position: absolute
  width: 23px
  height: 23px
  fill: #44aa00
  top: 5px
  left: 5px


.radioLabel
  display: block
  position: relative
  width: 100%
  height: 100%
  overflow: hidden
  transition: all .2s ease


.productRadio
  position: relative
  line-height: 20px
  display: flex
  align-items: center
  input
    position: absolute
    z-index: -1
    visibility: hidden
    &:checked + label
      border-color: $accent
      font-weight: 500
  label
    border-radius: 6px
    border: 2px solid #000
    white-space: nowrap
    padding: 0 5px
    min-width: 35px
    width: 100%
    height: 35px
    display: -webkit-box
    display: -ms-flexbox
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer

.productText
  position: relative
  max-width: 200px
  label
    border-radius: 6px
    border: 2px solid #000
    white-space: nowrap
    padding: 0 5px
    min-width: 35px
    width: 100%
    height: 35px
    display: -webkit-box
    display: -ms-flexbox
    display: flex
    -webkit-box-align: center
    -ms-flex-align: center
    align-items: center
    -webkit-box-pack: center
    -ms-flex-pack: center
    justify-content: center
    cursor: pointer
  input
    width: 100%
    max-width: 300px
    background-color: transparent
    font: 400 16px/1.2 $mainFont

.loader
  width: 92px
  height: 92px
  border-radius: 50%
  display: inline-block
  position: absolute
  left: calc(50% - 46px)
  top: calc(50% - 46px)
  background: linear-gradient(0deg, black 50%, $accentLight 100%)
  box-sizing: border-box
  animation: rotation 1s linear infinite

  &::after
    content: ''
    box-sizing: border-box
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
    width: 89px
    height: 89px
    border-radius: 50%
    background: #fafafa

@keyframes rotation
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)
.eye
  width: 28px
  height: 28px
  position: absolute
  right: 15px
  top: 16px