@use "src/styles/variables" as *
.profile
  display: grid
  grid-template-columns: 200px 1fr
  width: 100%
  gap: 20px
  h1
    margin-bottom: 1em
.profileWrap
  h1
    padding-top: 30px

.logo
  padding: 0
  img
    width: 50px
    height: 50px

.sidebar
  display: flex
  flex-direction: column
  gap: 10px
  position: sticky
  top: 20px
  a, button
    padding: 10px 0
    text-align: left
    text-transform: uppercase
  .active
    color: $accent

.rooms
  width: 100%
  height: 100%
  display: grid
  grid-template-columns: 1fr 360px
  gap: 10px

.nav
  display: flex
  align-items: center
  flex-wrap: wrap
  gap: 2px 5px
  width: fit-content
  margin-bottom: 30px
  a
    padding: 10px 20px
    text-transform: uppercase
    border-radius: 5px
    border: 2px solid $accentLight
    white-space: nowrap
    &.linkActive
      background-color: $accentLight
      color: #fff
  .navItem
    position: relative
    &:not(:last-of-type)
      border-right: 2px solid $accentLight
    input
      position: absolute
      opacity: 0
      width: 0
      height: 0
    label
      display: block
      padding: 10px 20px
      text-transform: uppercase
      cursor: pointer
    &.linkActive label
      background-color: $accentLight
      color: #fff

.name
  display: inline-block
  margin-right: auto
.roomForm
  display: flex
  flex-direction: column
  gap: 20px

.roomRow
  display: flex
  align-items: center
  gap: 20px
  margin-bottom: 30px
.edit
  display: flex
  flex-direction: column
  align-items: flex-start
  gap: 20px

.block
  display: grid
  grid-template-columns: 1fr
  max-width: 600px
  gap: 25px
  margin-top: 50px

.room
  display: grid
  grid-template-columns: 1fr 360px
  gap: 30px

.roomSidebar
  padding: 20px
  box-shadow: 4px 4px 10px rgba(0,0,0,.4)

.profileSidebar
  padding: 20px 0 0 30px
  position: relative

.product
  display: grid
  grid-template-columns: 2fr 3fr
  gap: 30px
  margin-top: 30px
  h2, h3
    margin-bottom: 1em

.approvalRemove
  display: flex
  gap: 20px
  flex-direction: column

.approvalRemoveButonset
  display: flex
  gap: 20px
  justify-content: center
  align-items: center

.table
  width: 100%
  max-width: 900px
  border: 1px solid #000
  border-radius: 8px
  margin-bottom: 10px

.tableHead, .tableRow
  display: grid
  grid-template-columns: 1fr 1fr 100px 100px
  gap: 15px

.tableHead
  font: 600 18px/1.2 $mainFont
  padding: 10px
  border-bottom: 1px solid #000

.tableRow
  padding: 5px 10px
  &:nth-of-type(odd)
    background-color: rgba(0, 0, 0, 0.1)

.svgEnabled, .svgDisabled
  width: 22px
  height: 22px

.svgEnabled
  fill: green
.svgDisabled
  fill: palevioletred