@use 'src/styles/variables' as *

.wrap
  display: grid
  grid-template-columns: 80vw 20vw
  height: 100vh

.productWrap
  display: grid
  grid-template-columns: 40% 60%
  margin: 30px 0 60px
  h3
    margin-bottom: 0.6em

.main
  height: 100%
  margin: 0 auto
  width: 100%
  padding: 0 30px
  overflow-y: auto

.left
  width: 25vw
  gap: 8px
  display: flex
  flex-direction: column

.right
  width: 100%

.attributes, .materials
  &>div
    line-height: 1.3
    span
      font: 400 16px/1.2 $mainFont
      &:first-child
        letter-spacing: -0.03em
      &:last-child
        font-weight: 500
.img
  overflow: hidden
  &.s
    aspect-ratio: 4/3
  &.m
    aspect-ratio: 5/4
  &.l
    aspect-ratio: 25/31
.block
    margin-bottom: 30px

.led
  display: inline-flex
  align-items: flex-start
  gap: 20px
  justify-content: flex-start
  .options
    flex-wrap: nowrap

.buttons
  display: flex
  align-items: center
  justify-content: center
  gap: 10px
  button
    border-radius: 3px
    display: flex
    align-items: center
    justify-content: center
    font-size: 22px
    color: #fff
    width: 25px
    height: 25px
    line-height: 1
    &:disabled
      opacity: 0.6
    &[value="minus"]
      background-color: $red
    &[value="plus"]
      background-color: $green


.blockWrap
  display: grid
  grid-template-columns: repeat(3, 1fr)
  gap: 20px
.options
  display: flex
  gap: 10px
  flex-wrap: wrap
  align-items: center
  justify-content: flex-start


.total
  font-size: 22px
  margin-bottom: 1em
  display: flex
  align-items: center
  justify-content: flex-start
  gap: 2px
  input
    display: none
.divider
  display: flex
  gap: 30px

.accessories
  .row
    display: flex
    gap: 10px
    justify-content: flex-start
    align-items: center
    &:not(:last-child)
      margin-bottom: 20px
  .close
    color: $red
    font-size: 26px
    font-weight: 700
  .number
    position: relative
    display: grid
    gap: 30px
    grid-template-columns: 200px 100px 1fr
    &:not(:last-child)
      margin-bottom: 20px
    h3
      margin: 0

.part
  margin-bottom: 10px