.login
  width: 100%
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  gap: 20px

.loginWrap
  max-width: 500px
  margin: 0 auto
  h1
    text-align: center
    margin-bottom: 1em

.signup
  display: inline-block
  margin-top: 1em
  a
    color: #FFCE90
    text-decoration: underline
.updated
  background-color: greenyellow !important