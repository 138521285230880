@use 'src/styles/variables' as *
.sidebar
  box-shadow: 4px 4px 10px rgba(0,0,0,.4)
  position: relative
  h2
    margin-bottom: 2em

.sidebarContent
  height: 100vh
  position: sticky
  top: 0

.sidebarList
  padding: 40px 10px 0
  width: 100%
  height: calc(100% - 70px)
  overflow-y: auto
  //padding: 15px 0 30px
  h3
    position: absolute
    top: 0
    left: 0
    right: 0
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2)
    z-index: 1
    background-color: #fff
    padding: 10px 10px
    height: 40px

.materials
  position: relative
  margin-bottom: 1em
  display: flex
  flex-direction: column

.buttons
  display: flex
  align-items: center
  justify-content: center
  gap: 10px
  button
    border-radius: 3px
    display: flex
    align-items: center
    justify-content: center
    font-size: 22px
    color: #fff
    width: 25px
    height: 25px
    line-height: 1
    &:disabled
      opacity: 0.6
    &[value="minus"]
      background-color: $red
    &[value="plus"]
      background-color: $green

.cartList
  display: flex
  flex-direction: column
  gap: 5px

.cartTotal
  font: 400 18px/1.2 $mainFont
  text-transform: uppercase
  span:last-child
    font-size: 20px
    font-weight: 500

.cartEmpty
  padding: 20px
  text-align: center
  font-weight: 500

.cartItem
  display: flex
  flex-direction: column
  align-items: flex-start
  gap: 5px
  &:not(:last-child)
    padding-bottom: 5px
    border-bottom: 2px solid $accent

.cartItemTotal
  font: 500 17px/1.2 $mainFont
.cartItemCode
  font: 500 17px/1.2 $mainFont

.cartItemTop
  display: flex
  align-items: center
  justify-content: flex-start
  gap: 10px

.itemClose
  color: $red
  font-size: 26px
  font-weight: 700

.itemimg
  display: block
  width: 60px
  height: 60px
  border-radius: 5px
  overflow: hidden
  flex-shrink: 0
  object-fit: contain

.itemName
  font: 500 18px/1.1 $mainFont
.itemOption
  display: flex
  gap: 3px
  font-size: 15px
  span:last-child
    font-weight: 500
.itemOptionCustom
  color: $red
.itemPriceBlock
  display: flex
  align-items: center
  justify-content: flex-start
  gap: 15px
  margin-top: .6em

.amount
  display: inline-block
  min-width: 1.6em
.itemSubPrice
  font: 500 18px/1.2 $mainFont

.itemTotalPrice
  margin-left: auto
  font: 500 20px/1.2 $mainFont

.block
  display: grid
  grid-template-columns: 1fr
  gap: 10px
  margin-bottom: 1.8em

.choose
  font: 500 18px/1.2 $mainFont

.chooseItem
  display: inline-block
  span:first-child
    display: inline-block
    margin-right: .2em
    letter-spacing: -0.02em
  span:nth-child(2)
    font-weight: 500

.sidebarImg
  width: 30px
  height: 30px
  flex-shrink: 0

.total
  display: block
  width: 100%
  height: 70px
  padding: 25px 10px
  position: absolute
  bottom: 0
  right: 0
  text-align: right
  font: 600 22px/1.2 $mainFont
  box-shadow: inset 0 4px 10px rgba(0,0,0,.2)

.submit
  position: absolute
  z-index: 1
  bottom: 30px
  right: 15px
  &.disabled
    background-color: #E6E6E6
    color: initial

.profileItem
  display: block

.part
  display: block
  margin-bottom: 7px