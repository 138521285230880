@use 'src/styles/variables' as *

.img
  max-width: 800px
  margin: 0 auto

.category
  display: flex
  align-items: center
  justify-content: center
  flex-wrap: wrap
  gap: 15px
  margin: 40px auto
.item
  position: relative
  transition: all .2s ease
  &:hover
    transform: scale(1.08)
  input
    position: absolute
    z-index: -1
    opacity: 0
  label
    position: relative
    text-align: center
    cursor: pointer
    padding: 10px
    box-shadow: 4px 4px 5px rgba(0,0,0,.3)
    border-radius: 5px
    transition: all .2s ease
    &:hover
      box-shadow: 6px 6px 6px rgba(0,0,0,.3)
  &.itemChecked label
    box-shadow: 10px 10px 12px rgba(0,0,0,.5)
    transform: scale(1.06)


.list
  display: grid
  grid-template-columns: 1fr 1fr
  gap: 20px 30px
.item
  display: flex
  gap: 20px
.itemImg
  width: 100%
  max-width: 320px
  overflow: hidden
  &.s
    aspect-ratio: 4/3
  &.m
    aspect-ratio: 5/4
  &.l
    aspect-ratio: 25/31

.name
  font: 500 20px/1.2 $mainFont
  margin-top: 1em
  margin-bottom: 0.6em
