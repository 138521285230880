@use "variables" as *
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap')

*
  box-sizing: border-box
  outline: none
  &::selection
    background: $accent
    color: #fff

html
  overflow-x: hidden

input, textarea, table, button
  font-family: $mainFont

body
  font: 400 16px/1.2 $mainFont
  margin: 0
  width: 100%
  padding: 0
  display: flex
  flex-direction: column
  justify-content: flex-start
  min-width: 295px
  position: relative
  color: $black
  background-color: #fff
html
  height: 100%

header,
footer,
section,
main
  position: relative

main
  min-height: calc(100vh - 50px)
footer
  height: 40px
button
  font-size: initial
  cursor: pointer
  background: transparent
  padding: 0

.app
  min-height: 100vh
  display: flex
  flex-direction: column
  gap: 10px

input
  margin: 0
  padding: 0

input:-webkit-autofill,
input:-webkit-autofill:focus
  transition: background-color 600000s 0s, color 600000s 0s

input[type=search]::-ms-clear
  display: none
  width: 0
  height: 0

input[type=search]::-ms-reveal
  display: none
  width: 0
  height: 0

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

input[type=number]
  -moz-appearance: textfield

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration
  display: none

input::-webkit-calendar-picker-indicator
  opacity: 0

nav
  list-style-type: none
ul
  list-style-type: none
  li
    padding: 0
    list-style-position: inside

ol
  margin: 0
  padding: 0
  li
    list-style-position: inside
    padding: 0
a
  color: $black
  text-decoration: none

h1, h2, h3, h4, p, ul
  margin: 0
  padding: 0

h4
  font: 700 16px/25px $mainFont

table
  width: 100%


img
  display: block
  width: 100%
  height: auto
  &::selection
    background: transparent


.img
  display: flex
  align-items: center
  justify-content: center
  overflow: hidden
  flex-shrink: 0
  img, picture
    width: 100%
    height: 100%
    display: block
    object-fit: cover

select
  width: 100%
  appearance: none
  -moz-appearance: none
  -webkit-appearance: none
  outline: none
  &::-ms-expand
    display: none

input, textarea
  border: none
  outline: none
  width: 100%

textarea
  resize: none

button
  border: none

address
  font-style: normal


.input
  background: transparent
.nowrap
  white-space: nowrap
.hidden
  display: none
.pointer
  cursor: pointer
.uppercase
  text-transform: uppercase
.underline
  text-decoration: underline
.block
  position: relative
  padding: 120px 0
  @include max(1023)
    padding: 100px 0
.relative
  position: relative
.button
  display: flex
  align-items: center
  justify-content: center
  gap: 8px
  cursor: pointer
  white-space: nowrap
  user-select: none
  padding: 15px 35px
  border-radius: 6px
  font: 400 18px/1.3 $mainFont
  transition: all 0.2s ease
  background: #fff
  text-transform: uppercase
  &.submit
    margin-top: 4em
    background: $accent
    color: $black
    width: 100%
  &.yellow
    background-color: $accent
    color: #fff
  &.red
    background-color: $red
    color: #fff


  &:disabled
    background-color: #E6E6E6
    color: initial
    pointer-events: none
    cursor: default
  @include max($maxMobile)
    width: 100%
  &.small
    padding: 10px 15px
    border-radius: 4px
    font: 400 15px/1.3 $mainFont

svg
  //width: 0
  //height: 0
  transition: all .2s ease

.link
  color: $textLink


.h1
  font: 500 42px/50px $mainFont

.h2
  font: 500 24px/32px $mainFont

button, a
  -webkit-tap-highlight-color: transparent

.blur
  position: fixed
  left: 0
  right: 0
  top: 0
  bottom: 0
  z-index: 3
  background-color: rgba(177,185,193, .7)
  &.active
    backdrop-filter: blur(5px)

.page
  position: relative
  width: 100vw
  height: 100vh
  overflow: hidden
  display: grid
  grid-template-columns: 1fr 360px
  &>h2
    text-transform: uppercase
    margin-bottom: 1em
  .main
    position: relative
    width: 100%
    height: 100%
    overflow-y: auto
    h1
      padding-bottom: .6em
.container
  position: relative
  padding: 0 30px 30px
  overflow-y: auto

.hidden
  display: none

.wrap
  margin: 0 auto
  max-width: 1200px
  width: 100%
  padding: 0 15px

.checkbox
  position: absolute
  opacity: 0
  width: 0
  height: 0
  //&:checked + .checkbox-label
  //  background-color: $accent
  &-wrap
    position: relative
  &-label
    //width: 22px
    //height: 22px
    //border-radius: 5px
    //display: block
    //border: 1px solid $accentLight
    //background-color: #fff